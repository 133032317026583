<template>
  <div>
    <Card id="login" style="width: 100%; text-align: left;">
      <p slot="title">
        <Icon type="ios-person"></Icon>{{$t('page.user.title')}}
      </p>
      <span slot="extra" v-if="editable && !inSoga"><a @click="logout">登出</a></span>
      <span v-else>&nbsp;</span>
      <div style="display: table;">
        <div style="display: table-cell;">
          <a v-if="!editing && editable" @click="changeHead" :title="$t('page.user.tip_head')">
            <UserHead :user="user" style="max-width: 80px; height: auto;"></UserHead>
          </a>
          <UserHead v-else :user="user" style="max-width: 80px; height: auto;"></UserHead>
        </div>
        <div style="display: table-cell; padding-left: 10px; vertical-align: top;">
          {{$t('page.user.lb_uname')}}：{{user.uname}}
          <br>
          {{$t('page.user.lb_nick')}}：<span v-if="!editing && editable">
            <Input v-model="user.nick" size="small" maxlength="20" show-word-limit style="width: 160px;"></Input>
            <Button icon="ios-create" size="small" shape="circle" @click="changeNick"></Button>
          </span><span v-else-if="user.nick">{{user.nick}}</span>
          <br>
          {{$t('page.user.lb_sex')}}：
          <RadioGroup v-if="!editing && editable" v-model="user.sex" @on-change="changeSex">
            <Radio :label="0">{{$t('page.user.v_sex_m')}}</Radio>
            <Radio :label="1">{{$t('page.user.v_sex_f')}}</Radio>
          </RadioGroup>
          <span v-else>
          {{user.sex === 0 ? $t('page.user.v_sex_m') : (user.sex === 1 ? $t('page.user.v_sex_f'):'?')}}
          </span>
          <br>
          <UserStars :value="user.credits"></UserStars>
          <p v-if="editable || auth.allowviewip">
            {{$t('page.user.lb_last_ip')}}:{{user.last_ip}}
          </p>
        </div>
      </div>
    </Card>
    <div style="width: 100%; text-align: right;" >
      <SogaPage v-if="inSoga" :current="req_page" :total="topic_num" :page-size="page_size" @on-change="page_change"
                :prev-text="label_page_prev" :next-text="label_page_next" class="pager"
                style="text-align: center;"></SogaPage>
      <Page v-else :current="req_page" :total="topic_num" :page-size="page_size" @on-change="page_change"
            :prev-text="label_page_prev" :next-text="label_page_next" size="small" class="pager"
            :style="inSoga ? 'text-align: left;':''" :transfer="true" :show-elevator="!inSoga" show-total/>
    </div>

    <Divider style="margin-top: 10px;"></Divider>
    <!-- 列表 -->
    <Table border :size="table_size" :no-data-text="$t('page.nodata')" :loading="loading" :columns="topic_header"
           :data="user.all_topics"></Table>
    <Divider style="margin-bottom: 10px;"></Divider>

    <div style="width: 100%; text-align: right;" >
      <SogaPage v-if="inSoga" :current="req_page" :total="topic_num" :page-size="page_size" @on-change="page_change"
                :prev-text="label_page_prev" :next-text="label_page_next" class="pager"
                style="text-align: center;"></SogaPage>
      <Page v-else :current="req_page" :total="topic_num" :page-size="page_size" @on-change="page_change"
            :prev-text="label_page_prev" :next-text="label_page_next" size="small" class="pager"
            :style="inSoga ? 'text-align: left;':''" :transfer="true" :show-elevator="!inSoga" show-total/>
    </div>
    <!-- 头像编辑 -->
    <Modal v-model="head_edit_show" width="340">
      <p slot="header" style="color:#f60;text-align:center;">
        <Icon type="ios-crop"></Icon>
        {{$t('page.user.title_head')}}
      </p>
      <div style="max-height: 70vh;text-align:center;align-content: center;">
        <VueCropper style="margin-bottom: 5px;height: 360px; width: 300px; "
                    ref="cropper"
                    :img="head_edit.img"
                    :outputSize="head_edit.size"
                    :outputType="head_edit.outputType"
                    :info="head_edit.info"
                    :full="head_edit.full"
                    :canMove="head_edit.canMove"
                    :canMoveBox="head_edit.canMoveBox"
                    :original="head_edit.original"
                    :autoCrop="head_edit.autoCrop"
                    :autoCropWidth="head_edit.autoCropWidth"
                    :autoCropHeight="head_edit.autoCropHeight"
                    :fixedBox="head_edit.fixedBox"></VueCropper>
      </div>
      <div style="text-align:center">
        <Button icon="ios-add" circle size="small"
                @click="changeScale(1)"></Button>
        <Button icon="ios-remove" circle size="small"
                @click="changeScale(-1)"></Button>
        <Button icon="ios-return-left" circle size="small"
                @click="rotateLeft"></Button>
        <Button icon="ios-return-right" circle size="small"
                @click="rotateRight"></Button>
      </div>
      <p slot="footer" style="text-align:center">
        <Button type="primary" @click="saveHead">{{$t('common.btn_save')}}</Button>
      </p>
    </Modal>
  </div>
</template>

<script>
  import { httpGet, httpPut, httpPostFile } from '@/api/data';
  import util from '@/libs/util';
  import SogaPage from './parts/soga_page';
  import UserHead from './parts/user_head';
  import UserStars from './parts/user_stars';
  import { VueCropper } from 'vue-cropper';
  import UserName from './parts/user_name';

  export default {
    name: 'User',
    components: {
      SogaPage,
      UserHead,
      UserStars,
      VueCropper
    },
    data () {
      return {
        inSoga: this.$store.state.inSoga,
        loading: true,

        head_edit_show: false,
        head_edit: {
          img: '', // 裁剪图片的地址
          info: true, // 裁剪框的大小信息
          outputSize: 0.8, // 剪切后的图片质量（0.1-1）
          full: true, // 输出原图比例截图 props名full
          outputType: 'png', // 裁剪生成额图片的格式
          canMove: true, // 能否拖动图片
          original: false, // 上传图片是否显示原始宽高
          canMoveBox: true, // 能否拖动截图框
          autoCrop: true, // 是否默认生成截图框
          autoCropWidth: 100,
          autoCropHeight: 120,
          fixedBox: true // 截图框固定大小
        },

        label_page_prev: this.$t('page.page_prev'),
        label_page_next: this.$t('page.page_next'),

        page_size: 10,
        curr_page: 1,
        req_page: 1,

        narrow: false,
        table_size: 'default',

        topic_num: 0,
        user: {
          uname: 0,
          nick: 0,
          head: '',
          sex: -1,
          all_topics: []
        },
        editable: false,
        // 仅自己才有此属性
        bak_user: {},
        editing: false,

        topic_header: [
          {
            title: this.$t('page.lh_topic'),
            key: 'topic',
            minWidth: 200,
            ellipsis: true,
            render: (h, p) => {
              const renders = [];
              if (p.row.closed) {
                renders.push(h('Icon', {
                  props: {
                    type: 'ios-remove-circle',
                    size: 'small'
                  }
                }));
              } else {
                renders.push(h('Icon', {
                  props: {
                    type: 'ios-document-outline',
                    size: 'small'
                  }
                }));
              }
              renders.push(h('router-link', {
                props: {
                  to: 'topic?id=' + p.row.id
                }
              }, '[' + p.row.flag.name + ']' + p.row.subject));
              if (p.row.has_pic) {
                renders.push(h('Icon', {
                  props: {
                    type: 'ios-image'
                  }
                }));
              }
              if (p.row.has_attach) {
                renders.push(h('Icon', {
                  props: {
                    type: 'ios-attach'
                  }
                }));
              }
              return renders;
            }
          },
          {
            title: this.$t('page.lh_stats'),
            key: 'reply',
            width: 75,
            render: (h, p) => {
              return [
                h('span', {
                  attrs: {
                    style: 'font-size:x-small;'
                  }
                }, p.row.posts),
                h('br'),
                h('span', {
                  attrs: {
                    style: 'font-size:x-small;'
                  }
                }, p.row.views)
              ];
            }
          },
          {
            title: this.$t('page.lh_last'),
            key: 'last',
            width: 150,
            render: (h, p) => {
              const user = p.row.last_user;
              if (!user) {
                return '';
              }
              return [
                h(UserName, { props: { user: user } }),
                h('br'),
                h('span', {
                  attrs: {
                    style: 'font-size:x-small;'
                  }
                }, util.showTimeStamp(p.row.updated_at, true))
              ];
            }
          }
        ]
      }
    },
    beforeRouteUpdate (to, from, next) {
      next();
      this.initData();
    },
    computed: {
      auth () {
        return util.userAuth(this.$store.state);
      }
    },
    methods: {
      initData () {
        // 获取数据
        httpGet('/v1/bbs/user/' + this.$route.query.id)
          .then(res => {
            if (!this.inSoga && this.editable) {
              const topics = res.all_topics;
              res.all_topics = null;
              this.bak_user = { ...res };
              res.all_topics = topics;
            }
            this.user = res;
            this.topic_num = res.all_topics.length;
            this.loading = false;
          }).catch(() => {
          });
      },
      page_change (page) {
        this.req_page = page;
        // this.getTopicList();
      },
      timeStamp (tt) {
        return util.showTimeStamp(tt);
      },
      logout () {
        this.$store.commit('login', null);
        this.$router.push({ name: 'home' });
      },
      changeNick () {
        const nick = this.user.nick.trim();
        if (nick === this.bak_user.nick) {
          return;
        }
        let userNick = this.bak_user.nick;
        if (/^\.+$/.test(nick)) {
          this.user.nick = userNick;
          return;
        }
        this.editing = true;
        // 修改昵称
        httpPut('/v1/user', {
          nick: nick
        }).then(res => {
          userNick = res.nick;
        }).catch(() => {
        }).finally(() => {
          this.user.nick = userNick;
          this.bak_user.nick = userNick;
          this.editing = false;
        });
      },
      changeSex () {
        // 修改性别
        let userSex = this.bak_user.sex;
        this.editing = true;
        httpPut('/v1/user', {
          sex: this.user.sex
        }).then(() => {
          userSex = this.user.sex;
        }).catch(() => {
        }).finally(() => {
          this.user.sex = userSex;
          this.bak_user.sex = userSex;
          this.editing = false;
        });
      },
      changeHead () {
        const file = document.createElement('input');
        file.setAttribute('type', 'file');
        file.setAttribute('accept', 'image/png, image/gif, image/jpg, image/jpeg, image/bmp');
        file.onchange = (/* e */) => {
          // console.error(file.files[0]);
          // const target = e.target || e.srcElement;
          const reader = new FileReader();
          reader.onload = (data) => {
            const res = data.target || data.srcElement;
            this.head_edit.img = res.result;
            this.head_edit_show = true;
          };
          reader.readAsDataURL(file.files[0]);
        }
        file.click();
      },
      saveHead () {
        this.$refs.cropper.getCropData((data) => {
          this.editing = true;
          const imgfile = util.base64ImgToFile(data); // 转换成file
          const formData = new FormData();
          formData.append('head', imgfile, imgfile.name); // 放到表单中，此处的file要和后台取文件时候的属性名称保持一致
          httpPostFile('/v1/user/head', formData).then(res => {
            this.user.head = res.head;
            this.head_edit_show = false;
          }).catch(() => {
          }).finally(() => {
            this.editing = false;
          });
        });
      },
      // 放大/缩小
      changeScale (num) {
        num = num || 1;
        this.$refs.cropper.changeScale(num);
      },
      // 左旋转
      rotateLeft () {
        this.$refs.cropper.rotateLeft();
      },
      // 右旋转
      rotateRight () {
        this.$refs.cropper.rotateRight();
      }
    },
    created () {
      this.editable = this.$store.state.user.id === parseInt(this.$route.query.id);
      if (window.screen.width < 730) {
        this.narrow = true;
        this.table_size = 'small';
        this.label_page_prev = '<';
        this.label_page_next = '>';
        this.topic_header[2].width = 100;
      }
    },
    mounted () {
      this.initData();
    }
  };
</script>

<style scoped>

</style>
